import React, { useState, useEffect } from 'react';
import './userFooter.styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'bootstrap';

const UserFooter = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [config, setConfig] = useState({ lang: localStorage.getItem('lang') });

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setConfig({ lang });
    }

    const handleNavigate = (link) => {
        window.open(link);
        // navigate(link);
    }

    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang !== null && lang !== "null") {
            handleChangeLanguage(lang)
        }
    }, []);

    return (
        <div className="user-footer">
            <div className="main-footer">
                <div className="main-content">
                    <div className="disclaimer">
                        <div className="title">
                            {t("footer.disclaimer.title")}
                        </div>
                        <div className="content">
                            {t("footer.disclaimer.content")}
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="important-note">
                        <div className="content">
                            {
                                t("footer.important_note", { returnObjects: true })
                                    .map((v, i) => <div className='item' key={`item_${i}`}>{v}</div>)
                            }
                        </div>
                        <div className="logo">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/${t("footer.logo")}.png`} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="post-footer">
                <div className="main-content">
                    <div className="copyright">
                        {t("footer.copyright")}
                    </div>
                    <div className="navigation">
                        {
                            t("footer.navigation", { returnObjects: true })
                                .map((v, i) => {
                                    const divider = (i == 0) ? "" : <div className='divider'>|</div>;
                                    return (
                                        <div className='item' key={`footer_item_${i}`}>
                                            {divider}
                                            <div className='label' key={`item_${i}`} onClick={e => handleNavigate(v.link)}>{v.label}</div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserFooter;