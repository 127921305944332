import React, { useCallback, useState, useEffect } from 'react';
import './filing.styles.scss';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import UserHeader from '../../components/user-header/userHeader.component';
import UserFooter from '../../components/user-footer/userFooter.component';

const Filing = () => {
    const navigate = useNavigate();
    const langMap = {
        "zh_CN": "zh_Hans",
        "zh_HK": "zh_Hant"
    };
    const langClassNormal = `lang`;
    const langClassActive = `lang lang-active`;
    const filterClassNormal = `option-container`;
    const filterClassActive = `option-container option-container-active`;
    const keywordClassNormal = `stock-search-container-hide`;
    const keywordClassActive = `stock-search-container`;
    const filterLabelInactive = `filter-label-inactive`;
    const filterLabelNormal = `filter-label`;
    const totalPageNum = 5;

    const { t, i18n } = useTranslation();
    const [configLang, setConfigLang] = useState(localStorage.getItem('lang'));
    const initialSelectedFilter = {
        period: t("filing.filter.default_period"),
        category: t("filing.filter.default_category"),
        formname: t("filing.filter.default_form_name"),
        keyword: ""
    };
    const [selectedFilter, setSelectedFilter] = useState({
        period: "",
        category: "",
        formname: "",
        keyword: ""
    })
    const [displaySelectedFilter, setDisplaySelectedFilter] = useState({
        period: "",
        category: "",
        formname: "",
    })
    const initialFilingBody = {
        "recperpage": 50,
        "pn": 1,
        "triggerFetch": false,
        "from_date": moment().subtract(12, 'months').startOf('month').format('YYYYMMDD').toString(),
        "to_date": moment().format('YYYYMMDD').toString()
    };
    const [filingBody, setFilingBody] = useState(initialFilingBody);
    const [stockData, setStockData] = useState()
    const [stockHtml, setStockHtml] = useState()
    const [tableData, setTableData] = useState()
    const [tableLoadingHtml, setTableLoadingHtml] = useState()
    const [tableHtml, setTableHtml] = useState()
    const initialPagingConfig = {
        total_page: 5,
        start_page: 1,
        end_page: 5,
        total_rec: 50,
        recperpage: 10,
        current_page: 1,
        current_page_index: 0
    };
    const [pagingConfig, setPagingConfig] = useState(initialPagingConfig)
    const [pagingHtml, setPagingHtml] = useState();
    const [activeFilter, setActiveFilter] = useState();
    const [activeLang, setActiveLang] = useState();
    const [timer, setTimer] = useState(null);
    const [filterCategoryArr, setFilterCategoryArr] = useState([]);
    const [filterFormnameArr, setFilterFormnameArr] = useState([]);
    const [filterCategoryHtml, setFilterCategoryHtml] = useState();
    const [filterFormnameHtml, setFilterFormnameHtml] = useState();
    const [initialTable, setInitialTable] = useState(false);
    const [fontFamily, setFontFamily] = useState("font-en");
    const [headlineFont, setHeadlineFont] = useState("");
    const initialSelectedLabel = {
        "period": "default",
        "category": "default",
        "formname": "",
        "stock": ""
    }
    const [selectedLabel, setSelectedLabel] = useState(initialSelectedLabel);

    const handleLangActive = (lang) => {
        setActiveLang(lang);
    }

    const handleChangeLanguage = (lang, triggerFetch = false) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setConfigLang(lang);
        handleLangActive(lang);
        const labelFormName = (selectedLabel.formname) ? selectedLabel.formname : t("filing.filter.default_form_name");
        setSelectedFilter({
            ...selectedFilter, ...{
                period: t(`filing.label.period.${selectedLabel.period}`),
                category: t(`filing.label.category.${selectedLabel.category}`),
                formname: labelFormName,
            }
        })
        setFilingBody({ ...filingBody, ...{ "lang": langMap[lang], triggerFetch } });
        if (lang == "en") {
            setFontFamily("font-en")
            setHeadlineFont("font-cs-headline")
        }
        else {
            setFontFamily("font-zh")
        }
    }

    const handleResetFilter = async () => {
        const authToken = localStorage.getItem('authToken');
        await setFilingBody({ ...initialFilingBody, ...{ token: authToken, "lang": langMap[configLang], "triggerFetch": false } });
        setSelectedFilter(initialSelectedFilter);
        setPagingConfig(initialPagingConfig);
        setSelectedLabel(initialSelectedLabel);
        setTableHtml();
        setPagingHtml();
    }

    const openFilter = (type) => {
        setActiveFilter(type)
    }

    const setPeriodByKey = (dateKey) => {
        const dateFormat = "YYYYMMDD";
        let from_date = null;
        let to_date = null;
        switch (dateKey) {
            case "all":
                // from_date = moment().subtract(12, 'months').startOf('month').format(dateFormat).toString();
                // to_date = moment().subtract(4, 'months').endOf('month').format(dateFormat).toString();
                from_date = moment().subtract(12, 'months').format(dateFormat).toString();
                to_date = moment().format(dateFormat).toString();
                break;
            case "this_week":
                from_date = moment().startOf('isoWeek').format(dateFormat).toString();
                to_date = moment().format(dateFormat).toString();
                break;
            case "last_week":
                from_date = moment().subtract(1, 'weeks').startOf('isoWeek').format(dateFormat).toString();
                to_date = moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormat).toString();
                break;
            case "this_month":
                from_date = moment().startOf('month').format(dateFormat).toString();
                to_date = moment().endOf('month').format(dateFormat).toString();
                break;
            case "last_month":
                from_date = moment().subtract(1, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(1, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "two_month_ago":
                from_date = moment().subtract(2, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(2, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "three_month_ago":
                from_date = moment().subtract(3, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(3, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "four_month_ago":
                from_date = moment().subtract(4, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(4, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "five_month_ago":
                from_date = moment().subtract(5, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(5, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "six_month_ago":
                from_date = moment().subtract(6, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(6, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "seven_month_ago":
                from_date = moment().subtract(7, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(7, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "eight_month_ago":
                from_date = moment().subtract(8, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(8, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "nine_month_ago":
                from_date = moment().subtract(9, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(9, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "ten_month_ago":
                from_date = moment().subtract(10, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(10, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "eleven_month_ago":
                from_date = moment().subtract(11, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(11, 'months').endOf('month').format(dateFormat).toString();
                break;
            case "twelve_month_ago":
                from_date = moment().subtract(12, 'months').startOf('month').format(dateFormat).toString();
                to_date = moment().subtract(12, 'months').endOf('month').format(dateFormat).toString();
                break;
            default:
                from_date = null;
                to_date = null;
                break;
        }
        setFilingBody({ ...filingBody, ...{ from_date, to_date, "triggerFetch": false } })
    }

    const handleOption = (type, value, label) => {
        setActiveFilter(null);
        switch (type) {
            case "period":
                setSelectedFilter({ ...selectedFilter, ...{ "period": label } })
                setSelectedLabel({ ...selectedLabel, ...{ "period": value } })
                setPeriodByKey(value);
                break;
            case "category":
                setSelectedFilter({ ...selectedFilter, ...{ "category": label } })
                setSelectedLabel({ ...selectedLabel, ...{ "category": value } })
                setFilingBody({ ...filingBody, ...{ "high_level_category": parseInt(value), "triggerFetch": false } })
                break;
            case "formname":
                setSelectedFilter({ ...selectedFilter, ...{ "formname": label } })
                setSelectedLabel({ ...selectedLabel, ...{ "formname": value } })
                setFilingBody({ ...filingBody, ...{ "form_name": value, "triggerFetch": false } })
                break;
            default:
                break;
        }
    }

    const handleCurrentPage = (currentPage) => {
        setPagingConfig({
            ...pagingConfig,
            ...{
                current_page: currentPage,
                current_page_index: (currentPage % 5) > 0 ? (currentPage % 5) - 1 : 4
            }
        });
    }

    const handleNextPagingRows = (modifier) => {
        const currentPn = filingBody.pn;
        const targetPn = filingBody.pn + modifier;
        const modifiedPagingConfig = {
            start_page: (targetPn * totalPageNum) - 4,
            end_page: targetPn * totalPageNum,
            current_page: (targetPn * totalPageNum) - 4,
            current_page_index: 0
        };

        setPagingConfig({
            ...pagingConfig,
            ...modifiedPagingConfig
        });

        const modifiedFilingBody = {
            "pn": targetPn,
            "triggerFetch": false
        };

        setFilingBody({
            ...filingBody,
            ...modifiedFilingBody
        });
    }

    const handlePowerSearch = (e) => {
        const key = e.target.value;
        if (!key) return
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                setActiveFilter("keyword");
                runPowerSearch(key);
            }, 500)
        );
    }

    const runPowerSearch = async (key) => {
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/stockdata/search`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ key })
        })
            .then(async res => {
                const resData = await res.json();
                setStockData(resData.data);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const handlePowerSearchResult = (v) => {
        setActiveFilter(null);
        setSelectedFilter({ ...selectedFilter, ...{ "keyword": `${v.ticker}, ${v.issuer_name}` } })
        setFilingBody({ ...filingBody, ...{ "symbol": v.ric, "triggerFetch": false } })
    }

    const handleFilterSubmit = () => {
        handleFetchFilingList(true);
    }

    const navigateFilingPdf = (v) => {
        // console.log(v)
        window.open(v.pdf_link)
    }

    const fetchGenerateToken = async () => {
        const authToken = localStorage.getItem('authToken');
        const authTokenExpiry = localStorage.getItem('authTokenExpiry');
        const expireCounter = ((Date.now() - authTokenExpiry) < 21600000);
        if (authToken && expireCounter) {
            // console.log("token generated from localStorage")
            setFilingBody({ ...filingBody, ...{ token: authToken, "triggerFetch": false } });
            setInitialTable(true);
            return;
        }
        else {
            localStorage.removeItem("authToken");
            localStorage.removeItem("authTokenExpiry");
        }

        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/news/encrypt-token-internal`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "broker": "CREDITSUISSE" })
        })
            .then(async res => {
                // console.log("token generated")
                const resData = await res.json();
                localStorage.setItem('authToken', resData.token)
                localStorage.setItem('authTokenExpiry', Date.now())
                setFilingBody({ ...filingBody, ...{ token: authToken, "triggerFetch": false } });
                setInitialTable(true);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const generateStockSearchHtml = () => {
        let stockSearchHtml = <div>
            {
                stockData.map((v, i) => {
                    return <div key={`stock_${i}`} onClick={e => handlePowerSearchResult(v)} >
                        {`${v.ticker}, ${v.issuer_name}`}
                    </div>
                })
            }
        </div>;
        setStockHtml(stockSearchHtml);
    }

    const generateCriteriaCategoryHtml = (resData) => {
        const catList = resData.data.categories;
        let arrCatList = [];
        for (const [key, value] of Object.entries(catList)) {
            arrCatList.push({ key, value })
        }
        setFilterCategoryArr(arrCatList)
    }

    const generateCriteriaFormnameHtml = (resData) => {
        const formnameList = resData.data.formName;
        setFilterFormnameArr(formnameList);
        // let formnameHtml = <>
        //     {
        //         formnameList.map((v, i) => {
        //             return <div key={`filter_formname_${i}`} className="option-item" onClick={e => handleOption("formname", v, v)} >
        //                 {v}
        //             </div>
        //         })
        //     }
        // </>;
        // setFilterFormnameHtml(formnameHtml);
    }

    const handleLogout = () => {
        localStorage.removeItem("user_serial_number");
        navigate(`/login`, { replace: true });
    }

    const handleFetchFilingList = async (newSearch) => {
        let processedFilingBody = filingBody;
        // console.log(processedFilingBody)
        if (newSearch) {
            // processedFilingBody = { ...filingBody, "pn": 1 };
            setFilingBody({ ...filingBody, ...{ "pn": 1 } })
            setPagingConfig(initialPagingConfig);
        }
        setTableData([]);
        setTableLoadingHtml(t("common.loading"));
        if (!filingBody.token || filingBody.token == "") {
            setTableLoadingHtml("");
            // console.log("Token not found in request body.")
            return;
        }
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/filing`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(processedFilingBody)
        })
            .then(async res => {
                const resData = await res.json();
                await setTableData(resData);
            })
            .catch(e => {
                console.log(e)
            })
        setTableLoadingHtml("");
    }

    const fetchCriteriaCategory = async () => {
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/criteria/filingcategories`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ lang: langMap[configLang] })
        })
            .then(async res => {
                const resData = await res.json();
                generateCriteriaCategoryHtml(resData);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const fetchCriteriaFormname = async () => {
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/criteria/filingformname`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        })
            .then(async res => {
                const resData = await res.json();
                // console.log(resData)
                generateCriteriaFormnameHtml(resData);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const generateTableHtml = () => {
        if (!tableData || tableData.status != 0) {
            setTableHtml(null)
            return
        }

        const rawTableDataList = tableData.data.datalist;
        const startSlice = (pagingConfig.current_page_index * pagingConfig.recperpage);
        const endSlice = startSlice + pagingConfig.recperpage;
        const tableDataList = rawTableDataList.slice(startSlice, endSlice);
        let table_html =
            <tbody>
                {
                    tableDataList.map((v, i) => {
                        let resCategory = "";
                        switch (configLang) {
                            case "zh_HK":
                                resCategory = v.high_level_category_hant;
                                break;
                            case "zh_CN":
                                resCategory = v.high_level_category_hans;
                                break;
                            default:
                                resCategory = v.high_level_category_en;
                                break;
                        }
                        return (
                            <tr key={`filing_tr_${i}`} onClick={e => navigateFilingPdf(v)}>
                                <td className='td-text'>
                                    <div className='td-item font-cs-et'>{v.date}</div>
                                    <div className='td-item show-mobile'>{resCategory}</div>
                                </td>
                                <td className='td-text'>
                                    <div className='td-item td-wrap'>{v.headline}</div>
                                    <div className='td-item show-mobile'>{`${v.feed_name}/${v.form_name}`}</div>
                                </td>
                                <td className='td-text td-collapsible'>{resCategory}</td>
                                <td className='td-text td-collapsible'>{`${v.feed_name}/${v.form_name}`}</td>
                                <td className='td-img navigate'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/pdf_black.png`} />
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>;
        setTableHtml(table_html)

        const calculatedPage = (rawTableDataList.length / 10);
        const filteredTotalPage = (rawTableDataList.length >= 50) ? 5 : Math.floor((calculatedPage == 0) ? calculatedPage : calculatedPage + 1);
        let paging_div = <div className='paging-container'>
            <div className="page-prev">
                {
                    (pagingConfig.start_page == 1)
                        ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_left_grey.png`} />
                        : <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_left.png`} onClick={e => handleNextPagingRows(-1)} />
                }
            </div>
            <div className="number-row">
                {
                    [...Array(filteredTotalPage)].map((v, i) =>
                        <div key={`paging_${i}`} className={`paging ${(i + pagingConfig.start_page == pagingConfig.current_page) ? "paging-active" : ""}`} onClick={e => handleCurrentPage(i + pagingConfig.start_page)} >
                            {i + pagingConfig.start_page}
                        </div>)
                }
            </div>
            <div className="page-next">
                {
                    (tableData.data.is_next_page)
                        ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_right.png`} onClick={e => handleNextPagingRows(1)} />
                        : <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_right_grey.png`} />
                }
            </div>
        </div>;
        setPagingHtml(paging_div);

        const processedSelectedPeriod = (configLang == "en" && selectedFilter.period == "All Periods") ? "Showing all SEC Filing" : `${t("filing.table.info_subtitle")}${(selectedFilter.period).toLowerCase()}${t("filing.table.info_subtitle_2")}`;
        setDisplaySelectedFilter({
            ...displaySelectedFilter, ...{
                title: t("filing.table.info_title"),
                period: processedSelectedPeriod,
            }
        })
    }

    const handleBodyOnClick = (e) => {
        e.preventDefault();
        const exclusionClassName = ["option-item", "selected-item", "filter-label", "filter-label-inactive"];
        if (e.target.className && !exclusionClassName.includes(e.target.className)) {
            setActiveFilter(null);
        }
    }

    useEffect(async () => {
        const userSerialNumber = localStorage.getItem('user_serial_number');
        if (!userSerialNumber) {
            navigate(`/login`, { replace: true });
        }

        const lang = localStorage.getItem('lang');
        if (lang !== null && lang !== "null") {
            handleChangeLanguage(lang);
        }

        fetchGenerateToken()

        fetchCriteriaCategory();
        fetchCriteriaFormname();
    }, []);

    useEffect(async () => {
        if (initialTable) {
            handleFetchFilingList()
        }
    }, [initialTable]);

    useEffect(async () => {
        // console.log(filingBody)
        if (filingBody.triggerFetch) {
            // console.log("triggerFetch")
            handleFetchFilingList();
            // handleFetchFilingList(true);
        }
    }, [filingBody]);

    useEffect(async () => {
        if (stockData) {
            generateStockSearchHtml();
        }
    }, [stockData]);

    useEffect(async () => {
        if (tableData) {
            generateTableHtml();
        }
    }, [tableData]);

    useEffect(async () => {
        handleFetchFilingList(false)
    }, [pagingConfig]);

    useEffect(async () => {
        fetchCriteriaCategory();
    }, [configLang]);

    return (
        <div className={`filing-container ${fontFamily}`} onClick={e => {
            handleBodyOnClick(e)
        }}>
            <div className="user-header">
                <div className="logo">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_header")}.png`} />
                </div>
                <div className="title">
                    {t("user.title")}
                </div>
                <div className="right-content">
                    <div className="logout-container">
                        <div className="btn-logout" onClick={e => handleLogout()}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ico-fn_signout.png`} />
                            {/* {t("header.logout")} */}
                        </div>
                    </div>
                    <div className="lang-container">
                        <div className={(activeLang == "en") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("en", true)}>
                            EN
                        </div>
                        <div className={(activeLang == "zh_HK") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_HK", true)}>
                            繁體
                        </div>
                        <div className={(activeLang == "zh_CN") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_CN", true)}>
                            简体
                        </div>
                    </div>
                </div>
            </div>
            <div className="post-header">
                <div className="nav-container">
                    <NavLink to={`/news`} className="nav-item" >
                        {t("user.nav_news")}
                    </NavLink>
                    <NavLink to={`/filing`} className="nav-item" >
                        {t("user.nav_filings")}
                    </NavLink>
                </div>
            </div>
            <div className="main-content">
                <div className={`title ${headlineFont}`}>
                    {t("filing.title")}
                </div>
                <div className="sub-title">
                    {t("filing.subtitle")}
                </div>
                <div className="filter-container">
                    <div className="title">
                        {t("filing.filter.title")}
                    </div>
                    <div className="input-container">
                        <div className="select">
                            <div className="selected-item" onClick={e => openFilter("period")}>
                                <div>
                                    {selectedFilter.period}
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_down.png`} />
                                </div>
                            </div>
                            <div className={(activeFilter == "period") ? filterClassActive : filterClassNormal}>
                                {
                                    t("filing.filter_period", { returnObjects: true })
                                        .map((v, i) => {
                                            return (
                                                <div className="option-item" key={`filter_period_${i}`} onClick={e => handleOption("period", v.value, v.label)}>
                                                    {v.label}
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <div className="select">
                            <div className="selected-item" onClick={e => openFilter("category")}>
                                <div className={(selectedLabel.category == "default") ? filterLabelInactive : filterLabelNormal}>
                                    {selectedFilter.category}
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_down.png`} />
                                </div>
                            </div>
                            <div className={(activeFilter == "category") ? filterClassActive : filterClassNormal}>
                                {
                                    filterCategoryArr.map((v, i) => {
                                        return <div key={`filter_cat_${i}`} className="option-item" onClick={e => handleOption("category", v.key, v.value)} >
                                            {v.value}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="select">
                            <div className="selected-item" onClick={e => openFilter("formname")}>
                                <div className={(selectedLabel.formname == "") ? filterLabelInactive : filterLabelNormal}>
                                    {selectedFilter.formname.replace("__ff__", "/")}
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_down.png`} />
                                </div>
                            </div>
                            <div className={(activeFilter == "formname") ? filterClassActive : filterClassNormal}>
                                {
                                    filterFormnameArr.map((v, i) => {
                                        return <div key={`filter_formname_${i}`} className="option-item" onClick={e => handleOption("formname", v, v)} >
                                            {v.replace("__ff__", "/")}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="input-container ic-mobile">
                        <div className="input-text-container">
                            <input type="text" name='news_search' className='input_text' placeholder={t("filing.filter_search")} onChange={e => setSelectedFilter({ ...selectedFilter, ...{ "keyword": e.target.value } })} onKeyUp={e => handlePowerSearch(e)} value={selectedFilter.keyword} autoComplete="off" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} />
                            <div className={(activeFilter == "keyword") ? keywordClassActive : keywordClassNormal}>
                                {stockHtml}
                            </div>
                        </div>
                        <div className="btn-filter-container">
                            <div className="btn-reset" onClick={e => handleResetFilter()}>
                                {t("filing.filter.reset")}
                            </div>
                            <div className="btn-filter" onClick={e => handleFilterSubmit()}>
                                {t("filing.filter.submit")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-info">
                    <div className="content-left">
                        <div className="title">
                            {displaySelectedFilter.title}
                        </div>
                        <div className="content">
                            {displaySelectedFilter.period}
                        </div>
                    </div>
                </div>
                <div className="table-loading-info">
                    {tableLoadingHtml}
                </div>
                <div className="table-content">
                    {
                        (tableHtml)
                            ? (<table>
                                <thead>
                                    <tr>
                                        <td>{t("filing.table.date")}</td>
                                        <td>{t("filing.table.headline")}</td>
                                        <td className='td-collapsible'>{t("filing.table.category")}</td>
                                        <td className='td-collapsible'>{t("filing.table.formname")}</td>
                                        <td className='td-center'>{t("filing.table.download")}</td>
                                    </tr>
                                </thead>
                                {tableHtml}
                            </table>
                            )
                            : ""
                    }
                </div>
                <div className="table-pagination">
                    {pagingHtml}
                </div>
            </div>
            <UserFooter />
        </div >
    )
}

export default Filing;