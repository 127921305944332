import React, { useCallback, useState, useEffect } from 'react';
import './news.styles.scss';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';

import UserHeader from '../../components/user-header/userHeader.component';
import UserFooter from '../../components/user-footer/userFooter.component';

const News = () => {
    const navigate = useNavigate();
    const langMap = {
        "zh_CN": "zh_Hans",
        "zh_HK": "zh_Hant"
    };
    const langClassNormal = `lang`;
    const langClassActive = `lang lang-active`;
    const filterClassNormal = `option-container`;
    const filterClassActive = `option-container option-container-active`;
    const keywordClassNormal = `stock-search-container-hide`;
    const keywordClassActive = `stock-search-container`;
    const totalPageNum = 5;

    const { t, i18n } = useTranslation();
    const [configLang, setConfigLang] = useState(localStorage.getItem('lang'));
    const initialSelectedFilter = {
        news_type: t("news.filter.default_type"),
        period: t("news.filter.default_period"),
        keyword: ""
    };
    const [selectedFilter, setSelectedFilter] = useState(initialSelectedFilter);
    const inititalNewsBody = {
        "token": "",
        "symbol": "",
        "lang": "",
        "news_type": "normal",
        "period": "all",
        "recperpage": 50,
        "pn": 1,
        "triggerFetch": false
    };
    const [newsBody, setNewsBody] = useState(inititalNewsBody);
    const [stockData, setStockData] = useState()
    const [stockHtml, setStockHtml] = useState()
    const [tableData, setTableData] = useState()
    const [tableLoadingHtml, setTableLoadingHtml] = useState()
    const [tableHtml, setTableHtml] = useState()
    const initialPagingConfig = {
        total_page: 5,
        start_page: 1,
        end_page: 5,
        total_rec: 50,
        recperpage: 10,
        current_page: 1,
        current_page_index: 0
    }
    const [pagingConfig, setPagingConfig] = useState(initialPagingConfig)
    const [pagingHtml, setPagingHtml] = useState();
    const [activeFilter, setActiveFilter] = useState();
    const [activeLang, setActiveLang] = useState();
    const [timer, setTimer] = useState(null);
    const [initialTable, setInitialTable] = useState(false);
    const [fontFamily, setFontFamily] = useState("font-en");
    const [headlineFont, setHeadlineFont] = useState("");
    const initialSelectedLabel = {
        "news_type": "default",
        "period": "default",
        "keyword": ""
    }
    const [selectedLabel, setSelectedLabel] = useState(initialSelectedLabel);

    const [displaySelectedFilter, setDisplaySelectedFilter] = useState({
        news_type: "",
        period: "",
    })

    const handleLangActive = (lang) => {
        setActiveLang(lang);
    }

    const handleChangeLanguage = (lang, triggerFetch = false) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setConfigLang(lang);
        handleLangActive(lang);
        setNewsBody({ ...newsBody, ...{ "lang": langMap[lang], triggerFetch } });
        setSelectedFilter({
            ...selectedFilter, ...{
                news_type: t(`news.label.news_type.${selectedLabel.news_type}`),
                period: t(`news.label.period.${selectedLabel.period}`),
            }
        })
        if (lang == "en") {
            setFontFamily("font-en")
            setHeadlineFont("font-cs-headline")
        }
        else {
            setFontFamily("font-zh")
        }
    }

    const openFilter = (type) => {
        setActiveFilter(type)
    }

    const handleOption = (type, value, label) => {
        // console.log(`value: ${value}`)
        switch (type) {
            case "news_type":
                setActiveFilter(null);
                setSelectedFilter({ ...selectedFilter, ...{ "news_type": label } })
                setSelectedLabel({ ...selectedLabel, ...{ "news_type": value } })
                setNewsBody({ ...newsBody, ...{ "news_type": value, "triggerFetch": false } })
                break;
            case "period":
                setActiveFilter(null);
                setSelectedFilter({ ...selectedFilter, ...{ "period": label } })
                setSelectedLabel({ ...selectedLabel, ...{ "period": value } })
                setNewsBody({ ...newsBody, ...{ "period": value, "triggerFetch": false } })
                break;
        }
    }

    const handlePowerSearchResult = (v) => {
        setActiveFilter(null);
        setSelectedFilter({ ...selectedFilter, ...{ "keyword": `${v.ticker}, ${v.issuer_name}` } })
        setNewsBody({ ...newsBody, ...{ "symbol": v.ric, "triggerFetch": false } })
    }

    const handleOpenNewsStory = (v) => {
        // console.log(v)
        // window.open(navigatePath);
        const navigatePath = `/news/story/${encodeURIComponent(decodeURIComponent(v.datetime))}/${encodeURIComponent(decodeURIComponent(v.story_id))}`;
        navigate(navigatePath, { replace: true });
    }

    const handleCurrentPage = (currentPage) => {
        setPagingConfig({
            ...pagingConfig,
            ...{
                current_page: currentPage,
                current_page_index: (currentPage % 5) > 0 ? (currentPage % 5) - 1 : 4
            }
        });
    }

    const handleNextPagingRows = (modifier) => {
        const currentPn = newsBody.pn;
        const targetPn = newsBody.pn + modifier;
        // const currentPn = pagingConfig.current_page;
        // const targetPn = currentPn + modifier;
        const modifiedPagingConfig = {
            start_page: (targetPn * totalPageNum) - 4,
            end_page: targetPn * totalPageNum,
            current_page: (targetPn * totalPageNum) - 4,
            current_page_index: 0
        };

        setPagingConfig({
            ...pagingConfig,
            ...modifiedPagingConfig
        });

        const modifiedNewsBody = {
            "pn": targetPn,
            "triggerFetch": false
        };

        setNewsBody({
            ...newsBody,
            ...modifiedNewsBody
        });
    }

    const handleResetFilter =  async () => {
        const authToken = localStorage.getItem('authToken');
        await setNewsBody({ ...inititalNewsBody, ...{ token: authToken, "lang": langMap[configLang], "triggerFetch": false } });
        setSelectedFilter(initialSelectedFilter);
        setPagingConfig(initialPagingConfig);
        setTableData();
        setPagingHtml();
        // setPagingConfig()
    }

    const generateTableHtml = () => {
        if (!tableData || tableData.status != 0) {
            setTableHtml(null)
            return
        }

        const rawTableDataList = tableData.data.datalist;
        const startSlice = (pagingConfig.current_page_index * pagingConfig.recperpage);
        const endSlice = startSlice + pagingConfig.recperpage;
        const tableDataList = rawTableDataList.slice(startSlice, endSlice);
        let table_html =
            <tbody>
                {
                    tableDataList.map((v, i) => {
                        return (
                            <tr key={`news_tr_${i}`} onClick={e => handleOpenNewsStory(v)}>
                                <td className='td-text font-cs-et'>{v.datetime}</td>
                                <td className='td-img'>
                                    {
                                        (configLang == "en")
                                            ? " "
                                            : <img src={`${process.env.PUBLIC_URL}/assets/images/icon/info_grey.png`} />
                                    }
                                </td>
                                <td className='td-text td-wrap'>{decode(v.title)}</td>
                                <td className='td-img navigate'><img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_right.png`} /></td>
                            </tr>
                        )
                    })
                }
            </tbody>;
        setTableHtml(table_html)

        const calculatedPage = (rawTableDataList.length / 10);
        const filteredTotalPage = (rawTableDataList.length >= 50) ? 5 : Math.floor((calculatedPage == 0) ? calculatedPage : calculatedPage + 1);
        let paging_div = <div className='paging-container'>
            <div className="page-prev">
                {
                    (pagingConfig.start_page == 1)
                        ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_left_grey.png`} />
                        : <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_left.png`} onClick={e => handleNextPagingRows(-1)} />
                }
            </div>
            <div className="number-row">
                {
                    [...Array(filteredTotalPage)].map((v, i) =>
                        <div key={`paging_${i}`} className={`paging ${(i + pagingConfig.start_page == pagingConfig.current_page) ? "paging-active" : ""}`} onClick={e => handleCurrentPage(i + pagingConfig.start_page)} >
                            {i + pagingConfig.start_page}
                        </div>)
                }
            </div>
            <div className="page-next">
                {
                    (tableData.data.is_next_page)
                        ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_right.png`} onClick={e => handleNextPagingRows(1)} />
                        : <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_right_grey.png`} />
                }
            </div>
        </div>;
        setPagingHtml(paging_div);

        const processedSelectedPeriod = (configLang == "en" && selectedFilter.period == "All Periods") ? "Showing all news" : `${t("news.table.info_content")}${(selectedFilter.period).toLowerCase()}${t("news.table.info_content_2")}`;
        setDisplaySelectedFilter({
            ...displaySelectedFilter, ...{
                news_type: `${selectedFilter.news_type}${t("news.table.info_title")}`,
                period: processedSelectedPeriod,
            }
        })
    }

    const handleFetchNewsHeadline = async (newSearch) => {
        let processedNewsBody = newsBody;
        if (newSearch) {
            // processedNewsBody = { ...newsBody, "pn": 1 };
            setNewsBody({ ...newsBody, ...{ "pn": 1 } })
            setPagingConfig(initialPagingConfig);
        }
        setTableData(null);
        setTableLoadingHtml(t("common.loading"));
        if (newsBody.token == "") {
            setTableLoadingHtml("");
            return;
        }
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/news/headline`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(processedNewsBody)
        })
            .then(async res => {
                const resData = await res.json();
                // const arrData = resData.data.datalist;
                await setTableData(resData);
            })
            .catch(e => {
                console.log(e)
            })
        setTableLoadingHtml("");
    }

    const handlePowerSearch = (e) => {
        const key = e.target.value;
        if (!key) return
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                setActiveFilter("keyword");
                runPowerSearch(key);
            }, 500)
        );
    }

    const runPowerSearch = async (key) => {
        // console.log(key);
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/stockdata/search`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ key })
        })
            .then(async res => {
                const resData = await res.json();
                setStockData(resData.data);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const fetchGenerateToken = async () => {
        const authToken = localStorage.getItem('authToken');
        const authTokenExpiry = localStorage.getItem('authTokenExpiry');
        const expireCounter = ((Date.now() - authTokenExpiry) < 21600000);
        if (authToken && expireCounter) {
            // console.log("token generated from localStorage")
            setNewsBody({ ...newsBody, ...{ token: authToken, lang: langMap[configLang], "triggerFetch": false } });
            setInitialTable(true);
            return;
        }
        else {
            localStorage.removeItem("authToken");
            localStorage.removeItem("authTokenExpiry");
        }

        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/news/encrypt-token-internal`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "broker": "CREDITSUISSE" })
        })
            .then(async res => {
                // console.log("token generated from API")
                const resData = await res.json();
                localStorage.setItem('authToken', resData.token)
                localStorage.setItem('authTokenExpiry', Date.now())
                setNewsBody({ ...newsBody, ...{ token: resData.token, lang: langMap[configLang], "triggerFetch": false } });
                setInitialTable(true);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const generateStockSearchHtml = () => {
        let stockSearchHtml = <div>
            {
                stockData.map((v, i) => {
                    return <div key={`stock_${i}`} onClick={e => handlePowerSearchResult(v)} >
                        {`${v.ticker}, ${v.issuer_name}`}
                    </div>
                })
            }
        </div>;
        setStockHtml(stockSearchHtml);
    }

    const handleLogout = () => {
        localStorage.removeItem("user_serial_number");
        navigate(`/login`, { replace: true });
    }

    const handleBodyOnClick = (e) => {
        e.preventDefault();
        const exclusionClassName = ["option-item", "selected-item"];
        if (e.target.className && !exclusionClassName.includes(e.target.className)) {
            setActiveFilter(null);
        }
    }

    useEffect(async () => {
        const userSerialNumber = localStorage.getItem('user_serial_number');
        if (!userSerialNumber) {
            navigate(`/login`, { replace: true });
        }

        const lang = localStorage.getItem('lang');
        if (lang !== null && lang !== "null") {
            handleChangeLanguage(lang);
        }

        fetchGenerateToken();
    }, []);

    useEffect(async () => {
        if (initialTable) {
            handleFetchNewsHeadline();
        }
    }, [initialTable]);

    useEffect(async () => {
        // console.log(newsBody)
        if (newsBody.triggerFetch) {
            // console.log("triggerFetch")
            handleFetchNewsHeadline();
        }
    }, [newsBody]);

    useEffect(async () => {
        if (stockData) {
            generateStockSearchHtml();
        }
    }, [stockData]);

    useEffect(async () => {
        await generateTableHtml();
    }, [tableData]);

    useEffect(async () => {
        handleFetchNewsHeadline(false)
    }, [pagingConfig]);

    return (
        <div className={`news-container ${fontFamily}`} onClick={e => { handleBodyOnClick(e) }}>
            <div className="user-header">
                <div className="logo">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_header")}.png`} />
                </div>
                <div className="title">
                    {t("user.title")}
                </div>
                <div className="right-content">
                    <div className="logout-container">
                        <div className="btn-logout" onClick={e => handleLogout()}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ico-fn_signout.png`} />
                            {/* {t("header.logout")} */}
                        </div>
                    </div>
                    <div className="lang-container">
                        <div className={(activeLang == "en") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("en", true)}>
                            EN
                        </div>
                        <div className={(activeLang == "zh_HK") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_HK", true)}>
                            繁體
                        </div>
                        <div className={(activeLang == "zh_CN") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_CN", true)}>
                            简体
                        </div>
                    </div>
                </div>
            </div>
            <div className="post-header">
                <div className="nav-container">
                    <NavLink to={`/news`} className="nav-item" >
                        {t("user.nav_news")}
                    </NavLink>
                    <NavLink to={`/filing`} className="nav-item" >
                        {t("user.nav_filings")}
                    </NavLink>
                </div>
            </div>
            <div className="main-content">
                <div className={`title ${headlineFont}`}>
                    {t("news.title")}
                </div>
                <div className="sub-title">
                    {t("news.subtitle")}
                </div>
                <div className="filter-container">
                    <div className="title">
                        {t("news.filter.label")}
                    </div>
                    <div className="input-container">
                        <div className="select">
                            <div className="selected-item" onClick={e => openFilter("news_type")}>
                                <div>
                                    {selectedFilter.news_type}
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_down.png`} />
                                </div>
                            </div>
                            <div className={(activeFilter == "news_type") ? filterClassActive : filterClassNormal}>
                                {
                                    t("news.filter_type", { returnObjects: true })
                                        .map((v, i) => {
                                            return (
                                                <div className="option-item" key={`news_type_${i}`} onClick={e => handleOption("news_type", v.value, v.label)}>
                                                    {v.label}
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <div className="select">
                            <div className="selected-item" onClick={e => openFilter("period")}>
                                <div>
                                    {selectedFilter.period}
                                </div>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/chevron_down.png`} />
                                </div>
                            </div>
                            <div className={(activeFilter == "period") ? filterClassActive : filterClassNormal}>
                                {
                                    t("news.filter_period", { returnObjects: true })
                                        .map((v, i) => {
                                            return (
                                                <div className="option-item" key={`period_${i}`} onClick={e => handleOption("period", v.value, v.label)}>
                                                    {v.label}
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <div className="input-text-container">
                            <input type="text" name='news_search' className='input_text' placeholder={t("news.filter_search")} onChange={e => setSelectedFilter({ ...selectedFilter, ...{ "keyword": e.target.value } })} onKeyUp={e => handlePowerSearch(e)} value={selectedFilter.keyword} autoComplete="off" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} />
                            <div className={(activeFilter == "keyword") ? keywordClassActive : keywordClassNormal}>
                                {stockHtml}
                            </div>
                        </div>
                    </div>
                    <div className="btn-container">
                        <div className="btn btn-reset" onClick={e => handleResetFilter()}>
                            {t("news.filter.btn_reset")}
                        </div>
                        <div className="btn btn-submit" onClick={e => handleFetchNewsHeadline(true)}>
                            {t("news.filter.btn_submit")}
                        </div>
                    </div>
                </div>
                <div className="table-info">
                    <div className="content-left">
                        <div className="title">
                            {displaySelectedFilter.news_type}
                        </div>
                        <div className="content">
                            {displaySelectedFilter.period}
                        </div>
                    </div>
                    <div className="content-right">
                        <div className="text">
                            <div>{t("news.table.reminder_cn")}</div>
                            <div>{t("news.table.reminder_en")}</div>
                        </div>
                        <div className="icon-info">
                            {
                                (configLang != "en")
                                    ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/info_grey.png`} />
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="table-loading-info">
                    {tableLoadingHtml}
                </div>
                <div className="table-content">
                    {
                        (tableHtml)
                            ? (
                                < table >
                                    <thead>
                                        <tr>
                                            <td>{t("news.table.date")}</td>
                                            <td></td>
                                            <td>{t("news.table.headline")}</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    {tableHtml}
                                </table >
                            )
                            : ""
                    }
                </div>
                <div className="table-pagination">
                    {pagingHtml}
                </div>
            </div>
            <UserFooter />
        </div >
    )
}

export default News;