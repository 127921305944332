import React, { useState, useEffect } from 'react';
import './login.styles.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap';

import ModalLogin from '../../components/modal-login/modalLogin.component';

const Login = () => {
    const navigate = useNavigate();
    const [config, setConfig] = useState({ lang: localStorage.getItem('lang') });
    const [activeLangEn, setActiveLangEn] = useState();
    const [activeLangZhHk, setActiveLangZhHk] = useState();
    const [activeLangZhCn, setActiveLangZhCn] = useState();
    const [loginHint, setLoginHint] = useState(false);
    const [fontFamily, setFontFamily] = useState("font-en");
    const [headlineFont, setHeadlineFont] = useState("");

    const [modalHide, setModalHide] = useState(false)

    const { t, i18n } = useTranslation();
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setConfig({ lang });
        handleLangActive(lang);
        if (lang == "en") {
            setFontFamily("font-en")
            setHeadlineFont("font-cs-headline")
        }
        else {
            setFontFamily("font-zh")
        }
    }

    const handleLangActive = (lang) => {
        switch (lang) {
            case 'en':
                setActiveLangEn("lang lang-active");
                setActiveLangZhHk("lang");
                setActiveLangZhCn("lang");
                break;
            case 'zh_HK':
                setActiveLangEn("lang");
                setActiveLangZhHk("lang lang-active");
                setActiveLangZhCn("lang");
                break;
            case 'zh_CN':
                setActiveLangEn("lang");
                setActiveLangZhHk("lang");
                setActiveLangZhCn("lang lang-active");
                break;
            default:
                setActiveLangEn("lang lang-active");
                setActiveLangZhHk("lang");
                setActiveLangZhCn("lang");
                break;
        }
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setLoginHint(false);
        fetchCheckLogin(e.target.login_sn.value)
    }

    const timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    const fetchCheckLogin = async (sn) => {
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/user/loginsn`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sn })
        })
            .then(async res => {
                // console.log(res)
                const resData = await res.json();
                // console.log(resData)
                if (resData.status == 0) {
                    setLoginHint(false);
                    localStorage.setItem('user_serial_number', sn);
                    navigate(`/news`, { replace: true });
                }
                else {
                    const delay = (process.env.REACT_APP_LOGIN_HINT_DELAY) ? process.env.REACT_APP_LOGIN_HINT_DELAY : 2000;
                    setLoginHint(true);
                    await timeout(delay);
                    setLoginHint(false);
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const handleBtnAccept = () => {
        // navigate(`/login`);
        setModalHide(true);
    }

    const handleBtnDecline = () => {
        alert("Decline button clicked. Redirect to current page.")
        navigate(`/`);
    }


    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang !== null && lang !== "null") {
            handleChangeLanguage(lang)
        }

        const userSerialNumber = localStorage.getItem('user_serial_number');
        if (userSerialNumber) {
            navigate(`/news`, { replace: true });
        }
    }, []);

    return (
        <div className={`login-container ${fontFamily}`}>
            {/* <ModalLogin /> */}
            <>
                {
                    (!modalHide)
                        ? (
                            <div className={`modal-login ${fontFamily}`}>
                                <div className="modal-box">
                                    <div className="header">
                                        <div className="lang-container">
                                            <div className={`${activeLangEn || 'lang'}`} id="lang_en" onClick={e => handleChangeLanguage("en")}>
                                                EN
                                            </div>
                                            <div className={`${activeLangZhHk || 'lang'}`} id="lang_zh_HK" onClick={e => handleChangeLanguage("zh_HK")}>
                                                繁體
                                            </div>
                                            <div className={`${activeLangZhCn || 'lang'}`} id="lang_zh_CN" onClick={e => handleChangeLanguage("zh_CN")}>
                                                简体
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-content">
                                        <div className={`title ${headlineFont}`}>
                                            {t("home.disclaimer.title")}
                                        </div>
                                        <div className="content">
                                            {
                                                t("home.disclaimer.content", { returnObjects: true })
                                                    .map((v, i) => <div className='item' key={`item_${i}`} dangerouslySetInnerHTML={{ __html: v }}></div>)
                                            }
                                            <div className='item'>
                                                <b>
                                                    {t("home.disclaimer.content_confirmation")}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="action">
                                        <div className="btn-container">
                                            <div className="btn btn-decline" onClick={e => handleBtnDecline()}>
                                                {t("home.btn_decline")}
                                            </div>
                                            <div className="btn btn-accept" onClick={e => handleBtnAccept()}>
                                                {t("home.btn_accept")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : ""
                }
            </>
            <div className="pre-header"></div>
            <div className="header">
                <div className="logo">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_header")}.png`} />
                </div>
                <div className="title">
                    {t("login.title")}
                </div>
                <div className="lang-container">
                    <div className={`${activeLangEn || 'lang'}`} id="lang_en" onClick={e => handleChangeLanguage("en")}>
                        EN
                    </div>
                    <div className={`${activeLangZhHk || 'lang'}`} id="lang_zh_HK" onClick={e => handleChangeLanguage("zh_HK")}>
                        繁體
                    </div>
                    <div className={`${activeLangZhCn || 'lang'}`} id="lang_zh_CN" onClick={e => handleChangeLanguage("zh_CN")}>
                        简体
                    </div>
                </div>
            </div>
            <div className="post-header"></div>
            <div className="main-content">
                <div className={`title ${headlineFont}`}>
                    {t('login.welcome_title')}
                </div>
                <div className="important-note">
                    <div className="title">
                        <b>
                            {t("login.important_note.title")}
                        </b>
                    </div>
                    <div className="content">
                        {t("login.important_note.content")}
                    </div>
                </div>
                <form className="form-box" onSubmit={e => handleSubmitLogin(e)}>
                    <div className="title">
                        <b>
                            {t("login.form.title")}
                        </b>
                    </div>
                    <div className="input-sn">
                        <input type="text" name="login_sn" id="login_sn" />
                    </div>
                    {
                        (loginHint)
                            ? (
                                <div className="login-hint" style={{ color: "red", textAlign: "left" }}>
                                    {t("common.login_hint_error")}
                                </div>
                            )
                            : ""
                    }
                    <div className="btn-submit-container">
                        <button className="btn" type="submit">
                            <div className="text">
                                {t("login.form.input_title")}
                            </div>
                            <div className="icon">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow_right_white.png`} />
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            <div className="footer">
                <div className="footer-content">
                    <div className="url">
                        {t("login.footer_link")}
                    </div>
                    <div className="logo">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_footer")}.png`} />
                    </div>
                </div>
            </div>
            <div className="post-footer">
                <div className="content">
                    <div className="text">
                        {t("login.configure_cookies")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;