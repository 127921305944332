import React, { useState, useEffect } from 'react';
import './home.styles.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap';

import ModalLogin from '../../components/modal-login/modalLogin.component';

const Home = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const lang = localStorage.getItem('lang');
        navigate(`/login`);
    }, []);

    return (
        <div className="login-container">
            {/* <ModalLogin />
            <div className="pre-header"></div>
            <div className="header"></div>
            <div className="main-content"></div>
            <div className="footer">
                <div className="footer-content">
                    <div className="url">
                        {t("login.footer_link")}
                    </div>
                    <div className="logo">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_footer")}.png`} />
                    </div>
                </div>
            </div>
            <div className="post-footer">
                <div className="content">
                    <div className="text">
                        {t("login.configure_cookies")}
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Home;