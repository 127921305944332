import en from './en.json'
import zh_cn from './zh_CN.json'
import zh_hk from './zh_HK.json'

const resources = {
  'en': {
    translation: en
  },
  'zh_CN': {
    translation: zh_cn
  },
  'zh_HK': {
    translation: zh_hk
  }
}

export default resources
