import React, { useCallback, useState, useEffect } from 'react';
import './newsStory.styles.scss';
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserHeader from '../../components/user-header/userHeader.component';
import UserFooter from '../../components/user-footer/userFooter.component';

const NewsStory = () => {
    const { datetime, storyid } = useParams();
    // console.log(storyid)
    const langMap = {
        "zh_CN": "zh_Hans",
        "zh_HK": "zh_Hant"
    };
    const langClassNormal = `lang`;
    const langClassActive = `lang lang-active`;

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [configLang, setConfigLang] = useState(localStorage.getItem('lang'));
    const [activeLang, setActiveLang] = useState();
    const [newsStoryBody, setNewsStoryBody] = useState({
        "token": "",
        "story_id": storyid,
        "lang": langMap[configLang]
    });
    const [newsStory, setNewsStory] = useState({
        "title": t("common.loading"),
        "datetime": datetime,
        "body": t("common.loading")
    });
    const [fontFamily, setFontFamily] = useState("font-en");
    const [headlineFont, setHeadlineFont] = useState("");

    const handleLangActive = (lang) => {
        setActiveLang(lang);
    }

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setConfigLang(lang);
        handleLangActive(lang);
        setNewsStoryBody({ ...newsStoryBody, ...{ "lang": langMap[lang] } });
        if(lang == "en"){
            setFontFamily("font-en")
            setHeadlineFont("font-cs-headline")
        }
        else{
            setFontFamily("font-zh")
        }
    }

    const handleBackNewsPage = () => {
        navigate(`/news`, { replace: true });
    }

    const fetchGenerateToken = async () => {
        const authToken = localStorage.getItem('authToken');
        const authTokenExpiry = localStorage.getItem('authTokenExpiry');
        const expireCounter = ((Date.now() - authTokenExpiry) < 21600000);
        if (authToken && expireCounter) {
            console.log("token generated from localStorage")
            setNewsStoryBody({ ...newsStoryBody, ...{ token: authToken, lang: langMap[configLang] } });
            return;
        }
        else {
            localStorage.removeItem("authToken");
            localStorage.removeItem("authTokenExpiry");
        }

        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/news/encrypt-token-internal`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "broker": "CREDITSUISSE" })
        })
            .then(async res => {
                // console.log("token generated")
                const resData = await res.json();
                localStorage.setItem('authToken', resData.token)
                localStorage.setItem('authTokenExpiry', Date.now())
                setNewsStoryBody({ ...newsStoryBody, ...{ token: resData.token, lang: langMap[configLang] } });
            })
            .catch(e => {
                console.log(e)
            })
    }

    const fetchNewsStory = async () => {
        setNewsStory({
            ...newsStory, ...{
                "title": t("common.loading"),
                "body": t("common.loading")
            }
        })
        if (newsStoryBody.token == "") return
        await fetch(`${process.env.REACT_APP_STRUCTURAL_API_HOST}/news/story`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newsStoryBody)
        })
            .then(async res => {
                const resData = await res.json();
                const resTitle = (resData.data.title) ? resData.data.title : "";
                setNewsStory({ ...newsStory, ...{ "title": resTitle, "body": resData.data.body } })
                console.log(resData)
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(async () => {
        window.scrollTo(0, 0)
        const userSerialNumber = localStorage.getItem('user_serial_number');
        if (!userSerialNumber) {
            navigate(`/login`, { replace: true });
        }

        const lang = localStorage.getItem('lang');
        if (lang !== null && lang !== "null") {
            handleChangeLanguage(lang);
        }
        fetchGenerateToken();
    }, []);

    useEffect(async () => {
        fetchNewsStory();
    }, [newsStoryBody]);

    return (
        <div className={`news-story-container ${fontFamily}`}>
            <div className="user-header">
                <div className="logo">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/${t("login.logo_header")}.png`} />
                </div>
                <div className="title">
                    {t("user.title")}
                </div>
                <div className="right-content">
                    <div></div>
                    <div className="lang-container">
                        <div className={(activeLang == "en") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("en")}>
                            EN
                        </div>
                        <div className={(activeLang == "zh_HK") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_HK")}>
                            繁體
                        </div>
                        <div className={(activeLang == "zh_CN") ? langClassActive : langClassNormal} onClick={e => handleChangeLanguage("zh_CN")}>
                            简体
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                {/* News Story {storyid} */}
                <div className="pre-title">
                    <div className="content content-left" onClick={e => handleBackNewsPage()}>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow_left.png`} /> {t("news_story.back_btn")}
                    </div>
                    <div className="content content-right">
                        <div className="text">
                            <div>{t("news.table.reminder_cn")}</div>
                            <div>{t("news.table.reminder_en")}</div>
                        </div>
                        <div className="icon-info">
                            {
                                (configLang != "en")
                                    ? <img src={`${process.env.PUBLIC_URL}/assets/images/icon/info_grey.png`} />
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="title">{newsStory.title}</div> */}
                <div className={`title ${headlineFont}`} dangerouslySetInnerHTML={{ __html: newsStory.title }} />
                <div className="datetime">{newsStory.datetime}</div>
                {/* <div className="body">{newsStory.body}</div> */}
                <div className="body" dangerouslySetInnerHTML={{ __html: newsStory.body }} />
            </div>
            <UserFooter />
        </div>
    )
}

export default NewsStory