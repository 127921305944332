import './App.css';
import './i18n';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import React, { useEffect } from 'react';

import Home from './routes/home/home.component';
import Login from './routes/login/login.component';
import News from './routes/news/news.component';
import NewsStory from './routes/news-story/newsStory.component';
import Filing from './routes/filing/filing.component';

const App = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGE_TITLE;
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en")
    }
  }, []);

  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes >
          <Route path={`/`} element={<Home />} />
          <Route path={`/login`} element={<Login />} />
          <Route path={`/news`} element={<News />} />
          <Route path={`/news/story/:datetime/:storyid`} element={<NewsStory />} />
          <Route path={`/filing`} element={<Filing />} />
        </Routes >
      </Router>
    </div>
  );
}

export default App;
